const pages = require("./src/redirection/pages.json")
const contentfulIds = require("./src/common/contentful-ids")
const routes = require("./src/common/routes")

const blogPath = pages[contentfulIds.blog]
const portfolioPath = pages[contentfulIds.portfolio]

// match paths like /blog or /blog/categories/design but not /blog/open-source-benefit
const createCategoriesRegExp = basePath =>
  new RegExp(`^${basePath}(?:/${routes.categories}/.*)?$`)

const blogCategoriesRegExp = createCategoriesRegExp(blogPath)
const portfolioCategoriesRegExp = createCategoriesRegExp(portfolioPath)

// don't scroll up when clicking blog/portfolio category filters, for the sake of UX
exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const { pathname } = routerProps.location
  const prevPathname = prevRouterProps?.location?.pathname ?? ""

  const testPathnames = regExp =>
    regExp.test(pathname) && regExp.test(prevPathname)

  const isDefaultScrollBehavior = !(
    testPathnames(blogCategoriesRegExp) ||
    testPathnames(portfolioCategoriesRegExp)
  )

  return isDefaultScrollBehavior
}
